<template>
  <div :class="$style.root">
    <div :class="$style.label">
      {{ $t(`fellows.status.${status}`, { count: count }) }}
    </div>
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  status: {
    type: String,
    validator(value) {
      return ['current', 'upcoming', 'past'].includes(value)
    }
  },
  count: {
    type: Number,
    default: 0
  }
})

const attributes = computed(() => {
  return baseStrapiGetAttributes(props?.data)
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  gap: var(--unit--spacer);
  align-items: center;
}

.label {
  text-transform: uppercase;
}
</style>
