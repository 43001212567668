<template>
  <i18nLink
    :to="{
      name: 'fellows-slug',
      params: {
        slug: attributes?.slug
      }
    }"
    :class="$style.root"
    :style="{
      '--deg': deg + 'deg'
    }"
    @mouseenter="setDeg"
  >
    <div :class="$style.content">
      {{ attributes?.title }}
    </div>
  </i18nLink>
</template>

<script setup>
import { sample, random } from 'lodash-es'

const props = defineProps({
  data: Object
})

const attributes = computed(() => {
  return baseStrapiGetAttributes(props?.data)
})

const deg = ref(0)

const setDeg = () => {
  const negative = sample([true, false])
  deg.value = random(4, 10) * (negative ? -1 : 1)
}
</script>

<style module>
.root {
  composes: reset-link from global;

  display: block;
  width: 100%;
}

.content {
  background-color: var(--color--primary);
  color: var(--color--white);

  padding: var(--unit--vertical) var(--unit--horizontal);

  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.root:hover .content {
  transform: rotate(var(--deg));
  background-color: var(--color--accent);
}
</style>
